<template>
  <LoginForm :logo-url="'https://generation-sis-assets.s3.eu-west-1.amazonaws.com/images/genex-logo-02.svg'" :fields="['email','go back']" :submit-text="'Reset password'"
             :body-text="'Please enter in your email address to request a password reset. A recovery link will then be emailed to you.'"
             @submitted="reset"></LoginForm>
</template>

<script>

import LoginForm from "@/components/auth/LoginForm";
import {AuthClient} from "@/clients/Auth";


export default {
  components: {LoginForm},
  name: "Login.forgotPassword",
  methods: {
    reset(email) {
      AuthClient.reset(email).then(() => {
        this.isLoading = false;
        this.$buefy.dialog.alert({
          title: `Reset Link Sent`,
          confirmText: `I understand`,
          hasIcon: true,
          type: "is-primary",
          message: `Now that you have requested a password reset please check your emails for next steps (remember to check your spam folder).`,
          onConfirm: () =>
              this.$router.push('/login'),
        });
      }).catch(
          err => {
            if (err.response.status === 422) {
              this.handleError(err)
            } else {
              this.handleError(err)
            }
          }
      )
    }
  }
}
</script>

<style scoped>

</style>
