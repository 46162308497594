<template>
  <LoginForm :logo-url="'https://generation-sis-assets.s3.eu-west-1.amazonaws.com/images/genex-logo-02.svg'" :fields="['password-create']" :submit-text="'Submit new password'"
             :body-text="'Please enter in your new password'"
             @submitted="requestNew"></LoginForm>
</template>

<script>

import LoginForm from "@/components/auth/LoginForm";
import {AuthClient} from "@/clients/Auth";


export default {
  components: {LoginForm},
  name: "Login.newPassword",
  props: ['token'],
  methods: {
    requestNew(params) {
      AuthClient.setNew({
        password: params.password,
        password_confirmation: params.password_confirmation,
        token: this.token,
      }).then(() => {
        this.isLoading = false;
        this.$buefy.snackbar.open({
          message: "Password Reset!",
          duration: 1000,
          queue: false,
          type: "is-primary"
        })
        setTimeout(() => this.$router.push('/login'), 1000)
      }).catch(
          err => {
            if (err.response.status === 422) {
              this.handleError(err)
            } else {
              this.handleError(err)
            }
          }
      )
    }
  }
}
</script>

<style scoped>

</style>
