import {
    ApiClientFactory
} from '@/clients/ApiClientFactory';

let factory = new ApiClientFactory();

/**
 *
 * @namespace LearnerReflectionClient
 */
export const LearnerReflectionClient = {


    Fetch(url) {
        /**
         * @property {Object} response.data The user object
         */
        return factory.get(url);
    },
    Submit(url, reflection) {
        return factory.post(url, reflection);
    }


};
