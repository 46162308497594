<template>
  <div class="apply-page">
    <div class="columns is-mobile apply-container has-background-white is-centered">
      <div class="column is-6 is-hidden-mobile apply-splash">
        <div class="columns is-centered">
          <div class="column is-8">
            <!--            <img class="apply-logo" src="@/assets/images/logo-transparent.png" alt/>-->
          </div>
        </div>
        <!--        <div class="columns is-hidden-mobile is-centered">-->
        <!--          <div-->
        <!--              @click="showVideoModal()"-->
        <!--              class="column is-8 has-text-centered has-pointer has-text-black video-launch-buttons"-->
        <!--          >-->
        <!--            <p class="is-size-4">Watch: How to Apply Tutorial</p>-->
        <!--            <p>-->
        <!--              <b-icon icon="play-circle-outline" size="is-large"></b-icon>-->
        <!--            </p>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
      <div class="column is-fullheight is-6-desktop is-10-mobile">
        <div class="mt-6 columns is-centered">
          <div class="column is-hidden-tablet is-4">
            <img class="apply-logo" src="@/assets/images/genex-logo-01-02.png" alt/>
          </div>
        </div>
        <!--        <div class="columns is-hidden-tablet is-centered">-->
        <!--          <div class="column is-8 has-text-centered has-text-black">-->
        <!--            <p class="is-size-4">Watch: How to Apply Tutorial</p>-->
        <!--            <iframe-->
        <!--                width="230"-->
        <!--                height="135"-->
        <!--                class="youtube-embed"-->
        <!--                src="https://www.youtube.com/embed/09IrEdd0UF4"-->
        <!--                frameborder="0"-->
        <!--                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"-->
        <!--                allowfullscreen-->
        <!--            ></iframe>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="columns is-centered">
          <div class="column is-8">
            <h2 class="is-size-3 mb-6 has-text-weight-semibold">Apply Today!</h2>
            <h3 class="is-size-4">Parent/Guardian information:</h3>
            <form @submit.prevent="submit" class="apply-form">
              <b-field label="First Name">
                <b-input
                    name="first name"
                    v-model="user.first_name"
                    placeholder="Bob"
                    required
                    autofocus
                />
              </b-field>
              <b-field label="Last Name">
                <b-input
                    name="last name"
                    placeholder="Smith"
                    v-model="user.last_name"
                    required
                    autofocus
                />
              </b-field>
              <b-field label="E-mail Address">
                <b-input
                    name="email"
                    type="email"
                    placeholder="bobsmith@email.com"
                    v-model="user.email"
                    required
                    autofocus
                />
              </b-field>
              <b-field v-if="existingUser === true" label="Password">
                <b-input
                    required
                    type="password"
                    :password-reveal="true"
                    v-model="user.password"
                    name="password"
                />
              </b-field>
              <ValidationObserver v-if="newUser" ref="observer">
                <p>Please enter a password below. It must be a minimum of 8 characters</p>
                <b-field label="Password">
                  <ValidationProvider rules="required|min|password:@confirm" v-slot="{ errors }">
                    <b-input
                        type="password"
                        :password-reveal="true"
                        v-model="user.password"
                        name="password"
                    />
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>

                <b-field label="Confirm password">
                  <ValidationProvider rules="required" name="confirm" v-slot="{ errors }">
                    <b-input
                        :password-reveal="true"
                        :loading="isLoading"
                        type="password"
                        v-model="user.password_confirmation"
                    />
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </ValidationObserver>

              <hr/>
              <p>To ensure compliance with data protection laws, we are asking you to take a few moments to read and
                accept our privacy policy.</p>
              <br>
              <p>
                By clicking Next Step, you agree to our <a target="_blank"
                                                           href="https://generation-sis-assets.s3.eu-west-1.amazonaws.com/downloads/Generation+Schools+Privacy+Policy+06-2021.pdf">Generation
                Group Privacy Policy</a>.</p>
              <b-field class="pt-3">
                <b-field grouped>
                  <div class="control">
                    <button
                        type="submit"
                        class="button is-primary"
                        :class="{'is-loading':isLoading}"
                    >Next Step
                    </button>
                  </div>
                  <div v-if="existingUser === true" class="control">
                    <router-link
                        target="_blank"
                        to="/password-recovery"
                        class="button is-primary is-outlined"
                    >Forgot password
                    </router-link>
                  </div>
                </b-field>
              </b-field>
            </form>
            <div class="mt-3">
              <b-field>
                <p>Already applied?
                  <router-link
                      to="/login"
                      class=" is-primary is-outlined"
                  > Click here to login
                  </router-link>
                </p>


              </b-field>
            </div>
          </div>
        </div>
      </div>
      <!--      <b-modal :content="videoEmbed" :active.sync="isVideoModalActive" class="has-text-centered"></b-modal>-->
    </div>
  </div>
</template>

<script>
import {AuthClient} from "@/clients/Auth";
import {ApplyClient} from "@/clients/Register";
import {ValidationProvider, ValidationObserver, extend} from "vee-validate";
import {required} from "vee-validate/dist/rules";
import Campus from "@/models/Campus";
import User from "@/models/User";

extend("required", {
  ...required,
  message: "This field is required",
});
extend("password", {
  params: ["target"],
  validate(value, {target}) {
    return value === target;
  },
  message: "Password confirmation does not match",
});
extend("min", (value) => {
  if (value.length >= 8) {
    return true;
  }
  return "The password must be at least 8 characters";
});
extend("min-items", {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ["length"],
});
export default {
  name: "Apply",
  components: {ValidationProvider, ValidationObserver},
  data() {
    return {
      videoEmbed: `
      <iframe
          width="560"
          height="315"
          class="youtube-embed"
          src="https://www.youtube.com/embed/09IrEdd0UF4?autoplay=1""
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>`,
      isVideoModalActive: false,
      isLoading: false,
      newUser: false,
      existingUser: false,
      user: {
        first_name: "",
        last_name: "",
        email: "",
        password: null,
        password_confirmation: null,
      },
      application: {
        campuses: []
      },
    };
  },
  created() {
    this.$store.dispatch("menu/fullPage", true);
    this.$store.dispatch("menu/asideRightToggle", false);
  },
  beforeDestroy() {
    this.$store.dispatch("menu/fullPage", false);
  },
  computed: {

    campuses() {
      return Campus.query().get();
    },
  },
  mounted() {
    ApplyClient.viewCampuses()
        .then((result) => {
          Campus.insert({
            data: result.data.data.map(campus => {
              return {...campus, ...campus.attributes}
            })

          })
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
  },
  methods: {
    showVideoModal() {
      this.isVideoModalActive = true;
    },
    login(password) {
      AuthClient.login({
        username: this.user.email,
        password: password,
        client_id: process.env.VUE_APP_CLIENT_ID,
        client_secret: process.env.VUE_APP_CLIENT_SECRET,
        grant_type: "password",
      })
          .then((result) => {
            this.$cookies
.set("access_token", result.data.access_token);
            this.$store.state.auth.accessToken = result.data.access_token

            this.$store.dispatch("loader/hide");
            User.api().post(`/api/1/users/${this.user.id}/accept-terms`, {}, {
              baseURL: process.env.VUE_APP_BASE_URL,
              headers: {
                ...{
                  'X-Requested-With': 'XMLHttpRequest',
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + result.data.access_token
                }

              }
            }).then(() => {
              this.$nextTick(() => {
                window.location.href = "/g/applications/create"
              })
            })

          })
          .catch(
              err => {
                if (err.response.status.toString()[0] === '4') {
                  this.$store.dispatch("toast/createToast", {
                    message: 'Please check that your login details are correct.',
                  });
                } else {
                  this.$store.dispatch("toast/createToast", {
                    message: JSON.stringify(err),
                  });
                }
              }
          )
    },
    submit() {
      this.isLoading = true;

      ApplyClient.checkIfExists(this.user.email)
          .then(() => {
          })
          .catch((err) => {
            this.isLoading = false;
            if (err.response.status === 302) {
              if (this.existingUser === false) {
                return this.$buefy.dialog.confirm({
                  title: "Existing User",
                  message:
                      "It looks like you already have an account. Please enter your password to continue",
                  confirmText: "Close",
                  canCancel: ["escape", "outside"],
                  type: "is-success",
                  hasIcon: true,
                  onConfirm: () => (this.existingUser = true),
                  onCancel: () => (this.existingUser = true),
                });
              }
              this.login(this.user.password);
            } else if (err.response.status === 404) {
              if (this.newUser === false) {
                return this.$buefy.dialog.confirm({
                  title: "New User",
                  message:
                      "It looks like you don't have an account yet. Please create a password to continue",
                  confirmText: "Close",
                  canCancel: ["escape", "outside"],
                  type: "is-link",
                  hasIcon: true,
                  icon: this.$tc('icons.check'),
                  onConfirm: () => (this.newUser = true),
                  onCancel: () => (this.newUser = true),
                });
              }
              this.$refs.observer.validate().then((valid) => {
                if (!valid) {
                  return false
                } else {
                  this.$store.dispatch("loader/show");
                  ApplyClient.register(this.user)
                      .then((response) => {
                        this.user.id = response.data.data.id
                        this.login(this.user.password);
                      })
                      .catch(() => {
                        this.$store.dispatch("toast/createToast", {
                          message: "Something went wrong",
                        });
                        this.$store.dispatch("loader/hide");
                      });
                }
              });
            }
          });
    },
  },
};
</script>
