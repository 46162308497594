<template>
  <LoginForm :logo-url="'https://generation-sis-assets.s3.eu-west-1.amazonaws.com/images/genex-logo-02.svg'" @submitted="login" :fields="['email','password','forgot password']"
             @forgotPasswordClick="forgotPassword"></LoginForm>
</template>

<script>

import LoginForm from "@/components/auth/LoginForm";
import {AuthClient} from "@/clients/Auth";
import User from "@/models/User";


export default {
  components: {LoginForm},
  name: "Login.view",
  mounted() {
    if (this.$store.state.auth.needs_to_accept === true
    ) {
      this.$buefy.dialog.confirm({
        title: this.$store.state.auth.terms_updated ? 'Privacy Policy Updated' : 'Privacy Policy',
        message: `<p>To ensure compliance with data protection laws, we are asking you to take a few moments to read and accept our ${this.$store.state.auth.terms_updated ? 'updated' : ''} privacy policy.</p>
          <br/><p>By clicking Accept, you agree to our <a target="_blank" href="https://generation-sis-assets.s3.eu-west-1.amazonaws.com/downloads/Generation+Schools+Privacy+Policy+06-2021.pdf">Generation Group Privacy Policy</a>.</p>`,
        cancelText: 'Cancel',
        confirmText: 'Accept',
        type: 'is-primary',
        onConfirm: () => {
          User.Accept(this.$store.state.user.id).then(() => {
            this.$nextTick(() => {
              if (this.$route.query.redirect) {
                window.location.href = this.$route.query.redirect
                return
              }
              window.location.href = "/"
            })
          })
        }
      })
    }
  },
  methods: {
    forgotPassword() {
      this.$router.push('/forgot-password')
    },
    login({email, password}) {
      AuthClient.login({
        username: email, password: password, grant_type: 'password',
        client_id: process.env.VUE_APP_CLIENT_ID,
        client_secret: process.env.VUE_APP_CLIENT_SECRET
      }).then(
          response => {
            console.log(response)
            this.$cookies.set('access_token', response.data.access_token)
            this.$store.state.auth.accessToken = response.data.access_token
            this.$nextTick(() => {
              if (this.$route.query.redirect) {
                window.location.href = this.$route.query.redirect
                return
              }
              window.location.href = "/"
            })
          }
      ).catch(
          err => {
            if (err.response.status.toString()[0] === '4') {
              this.$store.dispatch("toast/createToast", {
                message: 'Please check that your login details are correct.',
              });
            } else {
              this.handleError(err)
            }
          }
      )
    }
  }
}
</script>

<style scoped>

</style>
