<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-two-fifths">
            <router-view/>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FullPage',
  created() {
    this.$store.dispatch('menu/fullPage', true)
    this.$store.dispatch('menu/asideRightToggle', false)
  },
  beforeDestroy() {
    this.$store.dispatch('menu/fullPage', false)
  }
}
</script>
