<template>
  <div class="has-background-white section">
    <div v-if="loaded">
      <p class="has-text-centered is-size-4">
        {{ user.attributes.first_name }} {{ user.attributes.last_name }}'s reflection for
        {{ course_scope.attributes.syllabus }} on {{ lesson_plan.attributes.lesson_date }}
      </p>
      <form @submit.prevent="submit">
          <p>How comfortable do you feel about today’s lesson?</p>
        <p class="has-text-weight-semibold pb-3">I had no idea what was going on.</p>
        <div class="has-text-centered">
          <b-field style="max-width: 100%">
            <b-radio :disabled="has_reflection" required v-model="form.how_comfortable"
                     name="name"
                     :native-value="1">
              1
            </b-radio>
          </b-field>
          <b-field style="max-width: 100%">
            <b-radio :disabled="has_reflection" required v-model="form.how_comfortable"
                     name="name"
                     :native-value="2">
              2

            </b-radio>
          </b-field>
          <b-field style="max-width: 100%">
            <b-radio :disabled="has_reflection" required v-model="form.how_comfortable"
                     name="name"
                     :native-value="3">
              3
            </b-radio>
          </b-field>
          <b-field style="max-width: 100%">
            <b-radio :disabled="has_reflection" required v-model="form.how_comfortable"
                     name="name"
                     :native-value="4"
            >
              4
            </b-radio>
          </b-field>
          <b-field style="max-width: 100%">
            <b-radio :disabled="has_reflection" required v-model="form.how_comfortable"
                     name="name"
                     :native-value="5"
            >
              5
            </b-radio>
          </b-field>
        </div>
        <p class="has-text-weight-semibold pt-3">I understood everything and could teach others.</p>
        <b-field label="The easiest concept today was...">
          <b-input :disabled="has_reflection" v-model="form.easiest_concept" required></b-input>
        </b-field>
        <b-field label="The most difficult concept today was...">
          <b-input :disabled="has_reflection" v-model="form.hardest_concept" required></b-input>
        </b-field>
        <b-field position="is-centered" class="has-text-centered pt-4">
          <b-button :disabled="has_reflection" :loading="loading" native-type="submit" type="is-primary">Submit
          </b-button>
        </b-field>
      </form>
      <b-loading :can-cancel="false" v-model="loading" :is-full-page="false"></b-loading>

    </div>

  </div>
</template>

<script>
import {LearnerReflectionClient} from "@/clients/LearnerReflection";

export default {
  components: {

  },
  name: 'LearnerReflection',
  data() {
    return {
      loaded: false,
      form: {
        how_comfortable: null,
        easiest_concept: null,
        hardest_concept: null
      },
      user: null,
      lesson_plan: Object,
      course_scope: Object,
      reflection: null,
      has_reflection: false,
      save_link: null,
      loading: false,

    }
  },
  computed: {

    queryUrl() {
      if (this.$route.query.url) {
        return this.$route.query.url

      }
      return 'https://staging.api.gen-sis.codespace.co.za/api/1/public-learner-reflections/1/1?expires=1622122060&signature=5fc9440d6faeca9dd99d2359b053b5a226d29a0d1a950d8dd1c966bdb16c020b'
    }
  },
  methods: {
    submit() {
      this.loading = true
      LearnerReflectionClient.Submit(this.save_link, this.form).then(() => {
        this.loading = false
        this.$buefy.dialog.alert('Reflection Submitted!')
        this.has_reflection = true
      }).catch(err => {
        this.loading = false
        this.handleError(err)
      })

    }
  },
  mounted() {
    if (!Object.prototype.hasOwnProperty.call(this.$route.query, 'url')) {
      this.$store.dispatch('toast/createToast', {message: 'Invalid Reflection Link'}).then(() => {
        setTimeout(() => {
          this.$router.push('/login')

        }, 2500)
      })
    } else {
      LearnerReflectionClient.Fetch(decodeURI(this.queryUrl) + '&signature=' + this.$route.query.signature).then(response => {
        this.course_scope = response.data.course_scope
        this.lesson_plan = response.data.lesson_plan
        this.user = response.data.user
        if (Object.prototype.hasOwnProperty.call(response.data, 'learner_reflection_save_link')) {
          this.has_reflection = false
          this.save_link = response.data.learner_reflection_save_link
        }
        if (Object.prototype.hasOwnProperty.call(response.data, 'reflection')) {
          this.has_reflection = true
          this.form = response.data.reflection.attributes
        }
        this.loaded = true
      }).catch(() => {
        this.$store.dispatch('toast/createToast', {message: 'Invalid Reflection Link'}).then(() => {
          setTimeout(() => {
            this.$router.push('/login')

          }, 2500)
        })


      })
    }

  }

}
</script>