<template>

  <router-view/>

</template>

<script>
export default {
  name: 'PassThrough',

}
</script>
