var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.logoUrl)?_c('img',{staticClass:"px-4",attrs:{"alt":_vm.logoAlt,"src":_vm.logoUrl}}):_vm._e(),_c('card-component',{staticClass:"has-background-transparent",staticStyle:{"box-shadow":"none"},attrs:{"transparent":""}},[(_vm.bodyText)?_c('p',[_vm._v(_vm._s(_vm.bodyText))]):_vm._e(),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('ValidationObserver',{ref:"loginObserver"},[(_vm.hasField('email'))?_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.hasField('email'))?_c('b-field',{attrs:{"message":{'Please enter your email address':errors.length>0},"type":{'is-danger':errors.length>0},"label":"E-mail Address"}},[_c('b-input',{attrs:{"autofocus":"","custom-class":"has-background-white","name":"email","type":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1):_vm._e()]}}],null,false,2137324464)}):_vm._e(),(_vm.hasField('password'))?_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.hasField('password'))?_c('b-field',{attrs:{"type":{'is-danger':errors.length>0},"label":"Password","message":{'Please enter your password':errors.length>0}}},[_c('b-input',{attrs:{"custom-class":"has-background-white","name":"password","type":"password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1):_vm._e()]}}],null,false,3973232552)}):_vm._e()],1),(_vm.hasField('password-create'))?_c('ValidationObserver',{ref:"observer"},[_c('b-field',{attrs:{"label":"Password"}},[_c('ValidationProvider',{attrs:{"rules":"required|min|password:@confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"name":"password","placeholder":"password","required":"","type":"password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,false,989006651)})],1),_c('b-field',{attrs:{"label":"Confirm password"}},[_c('ValidationProvider',{attrs:{"name":"confirm","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"placeholder":"confirm password","required":"","type":"password"},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,false,3071602999)})],1)],1):_vm._e(),_c('hr'),_c('b-field',[_c('b-button',{class:{'is-loading':_vm.isLoading},attrs:{"expanded":"","native-type":"submit","type":"is-primary"}},[_vm._v(" "+_vm._s(_vm.submitText)+" ")])],1),(_vm.hasField('forgot password'))?_c('b-field',[_c('b-button',{attrs:{"expanded":"","native-type":"button","type":"is-light"},on:{"click":function($event){$event.preventDefault();return _vm.forgotPasswordClick.apply(null, arguments)}}},[_vm._v("Forgot password ")])],1):_vm._e(),(_vm.hasField('go back'))?_c('b-field',[_c('b-button',{attrs:{"expanded":"","native-type":"button","outlined":"","type":"is-link"},on:{"click":function($event){$event.preventDefault();return _vm.goBack.apply(null, arguments)}}},[_vm._v("Return to login ")])],1):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }