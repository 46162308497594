<template>
  <div class="">
    <img v-if="logoUrl" :alt="logoAlt" :src="logoUrl" class="px-4"/>
    <card-component class="has-background-transparent" style="box-shadow: none" transparent>
      <p v-if="bodyText">{{ bodyText }}</p>
      <form @submit.prevent="submit">
        <ValidationObserver ref="loginObserver">

          <ValidationProvider v-if="hasField('email')" v-slot="{ errors }" rules="required">

            <b-field  :message="{'Please enter your email address':errors.length>0}" :type="{'is-danger':errors.length>0}" v-if="hasField('email')" label="E-mail Address">
              <b-input v-model="form.email" autofocus custom-class="has-background-white" name="email" type="email"/>

            </b-field>
          </ValidationProvider>

          <ValidationProvider v-if="hasField('password')" v-slot="{ errors }" rules="required">

            <b-field v-if="hasField('password')" :type="{'is-danger':errors.length>0}"
                     label="Password" :message="{'Please enter your password':errors.length>0}">

              <b-input v-model="form.password" custom-class="has-background-white" name="password" type="password"/>


            </b-field>
          </ValidationProvider>

        </ValidationObserver>
        <ValidationObserver v-if="hasField('password-create')" ref="observer">
          <b-field label="Password">
            <ValidationProvider v-slot="{ errors }" rules="required|min|password:@confirm">
              <b-input
                  v-model="form.password"
                  name="password" placeholder="password"
                  required
                  type="password"
              />
              <span>{{ errors[0] }}</span>
            </ValidationProvider>
          </b-field>

          <b-field label="Confirm password">
            <ValidationProvider v-slot="{ errors }" name="confirm" rules="required">
              <b-input v-model="form.password_confirmation"
                       placeholder="confirm password" required type="password"/>
              <span>{{ errors[0] }}</span>
            </ValidationProvider>
          </b-field>
        </ValidationObserver>

        <hr/>
        <b-field>
          <b-button
              :class="{'is-loading':isLoading}"
              expanded
              native-type="submit"
              type="is-primary"
          > {{ submitText }}
          </b-button>
        </b-field>
        <b-field v-if="hasField('forgot password')">
          <b-button
              expanded
              native-type="button"

              type="is-light"
              @click.prevent="forgotPasswordClick">Forgot password
          </b-button>
        </b-field>
        <b-field v-if="hasField('go back')">
          <b-button
              expanded
              native-type="button"
              outlined
              type="is-link"
              @click.prevent="goBack">Return to login
          </b-button>
        </b-field>
        <!--        <a @click.prevent="registerClick">Not yet registered? Start here.-->
        <!--        </a>-->
      </form>
    </card-component>
  </div>


</template>

<script>
import CardComponent from "../layout/CardComponent";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {required} from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required",
});
extend("password", {
  params: ["target"],
  validate(value, {target}) {
    return value === target;
  },
  message: "Password confirmation does not match",
});
extend("min", (value) => {
  if (value.length >= 8) {
    return true;
  }
  return "The password must be at least 8 characters";
});
export default {
  name: "LoginForm",
  components: {
    CardComponent, ValidationObserver, ValidationProvider
  },
  data() {
    return {
      form: {
        email: null,
        password: null,
        password_confirmation: null
      },
    }
  },
  props: {
    bodyText: {
      type: String, default() {
        return null
      }
    },
    submitText: {
      type: String,
      default() {
        return 'Login'
      }
    },
    fields: {
      type: Array,
      default() {
        return ['email', 'password', 'forgot password']
      },
    },
    logoUrl: {
      type: String,
      required: false
    },
    logoAlt: {
      type: String,
      default() {
        return 'The platform logo'
      }
    },
    isLoading: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  methods: {
    goBack() {
      this.$router.push('/login')
    },
    hasField(field) {
      return this.fields.includes(field)
    },
    forgotPasswordClick() {
      this.$emit('forgotPasswordClick')
    },
    registerClick() {
      this.$emit('registerClick')
    },
    submit() {
      if (this.hasField('password-create')) {
        this.$refs.observer.validate().then(success => {
          if (!success) {
            return
          }
          this.$emit('submitted', this.form)

        })
      } else {
        this.$refs.loginObserver.validate().then(success => {
          if (!success) {
            return
          }
          this.$emit('submitted', this.form)
        })

      }
    }
  }
}
</script>

<style scoped>

</style>
